import { rest } from 'msw';
import { MakePaymentResponse } from '../types';

const response: MakePaymentResponse = {
    responseCode: 'WT_DOC', //'ExceededDailyPaymentTransactions', //'WT_MSK',
    //responseCode: 'WT_FRE',
    /*approvedAmount: 300,
    balance: 150,
    daysLeftToFund: 42,
    creditLimit: 300,*/    
    paymentResponse: {
        applicationId: "0006000524",
        numberOfAttempts: 1,
        errorMessage: '',
        goodFundsDate: "03/14/2023",
        exceededDailyTransaction: false,
        daysLeftToFund: 34,
        balance: 132,
        creditLimit: 250,
        approvedAmount: 26,
        leadId: '',
        isResponseTimeout: false,
    },
} as MakePaymentResponse;

export const makePayment = [
    rest.post(`${process.env.REACT_APP_DEFAULT_API_BASE_URL}/api/application/:applicationId/makePayment`, async (req, res, ctx) => {
        const { applicationId } = req.params;
        const json = await req.json();
        console.log('applicationId', applicationId);
        console.log('payload', json);
        await new Promise(r => setTimeout(r, 500));
        return res(
            ctx.status(200),
            ctx.json(response)
        );
    }),
];
