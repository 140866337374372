import { isValidRawPhone, rawPhonePattern } from './validations';

const formatPhone = (phone?: string): string | undefined => {
    if (!isValidRawPhone(phone)) {
        return undefined;
    }

    var slugs = phone?.match(rawPhonePattern);
    if (!slugs) {
        return undefined;
    }

    return `${slugs[1]}-${slugs[2]}-${slugs[3]}`;
}

export {
    formatPhone,
};
