import { atom } from 'recoil';
import {
    ApplicationModel,
    ConfigurationResponse,
    CreateLeadResponse,
    DecisionsResponse,
    SendCheckAppStatusOtpRequest,
    SendCheckAppStatusOtpResponse,
    GADataLayerModel
} from '../types';
import { CardTypes } from '../utils/constants';

export const applicationState = atom<ApplicationModel>({
    key: 'applicationState',
    default: {
        primaryPhoneType: 'C',
        cardType: 'basic',
    },
});

export const responseCodeState = atom<string | undefined>({
    key: 'responseCodeState',
    default: undefined,
});

export const selectedApplicationIdState = atom<string | undefined>({
    key: 'selectedApplicationIdState',
    default: undefined,
});

export const createLeadResponseState = atom<CreateLeadResponse | undefined>({
    key: 'createLeadResponseState',
    default: undefined,
});

export const decisionsResponseState = atom<DecisionsResponse | undefined>({
    key: 'decisionsResponseState',
    default: undefined,
});

export const sendCheckAppStatusOtpRequestState = atom<SendCheckAppStatusOtpRequest | undefined>({
    key: 'sendCheckAppStatusOtpRequestState',
    default: undefined,
});

export const sendCheckAppStatusOtpResponseState = atom<SendCheckAppStatusOtpResponse | undefined>({
    key: 'sendCheckAppStatusOtpResponseState',
    default: undefined,
});

export const timeoutExpiredState = atom<boolean | undefined>({
    key: 'timeoutExpiredState',
    default: false,
});

export const checkAppState = atom<boolean | undefined>({
    key: 'checkAppState',
    default: false,
});

export const paymentSucceededState = atom<boolean | undefined>({
    key: 'paymentSucceededState',
    default: false,
});

export const configurationResponseState = atom<ConfigurationResponse | undefined>({
    key: 'configurationResponseState',
    default: undefined,
});

export const utmSourceNameState = atom<string>({
    key: 'utmSourceName',
    default: ''
});

export const cardTypeState = atom<string>({
    key: 'cardTypeState',
    default: 'basic'
});

export const emailAddressState = atom<string>({
    key: 'emailAddress',
    default: ''
});

export const launchCardTunerdOnState = atom<boolean>({
    key: 'launchCardTunerdOn',
    default: true
});

export const firstNameState = atom<string>({
    key: 'firstName',
    default: ''
});

export const userIdState = atom<string>({
    key: 'userId',
    default: ''
});

export const nidState = atom<string>({
    key: 'nid',
    default: ''
});

export const gaDataLayerState = atom<GADataLayerModel>({
    key: 'gaDataLayer',
    default: {
        user_id: '',
        app_started: true,
        NID_: undefined,
        SID_: undefined,
        step_name: undefined,
        card_selected: undefined,
        est_housing_payment: undefined,
        total_yearly_outcome: undefined,
        credit_line_amount: undefined,
        credit_line_type: undefined,
        card_fund_selection: undefined,
        card_fund_result: undefined,
    }
});

export const applicationBeforeDownsellState = atom<ApplicationModel | null>({
    key: 'applicationBeforeDownsell',
    default: null
})

export const downsellState = atom<boolean>({
    key: 'downsell',
    default: false
})

export const downsellSourcesState = atom<CardTypes[]>({
    key: 'downsellSources',
    default: []
})

export const productIdBeforeDownsellState = atom<string>({
    key: 'productIdBeforeDownsell',
    default: ''
})