import React, { useEffect, useState } from "react";
//import { useQueryClient } from 'react-query';
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { selectedApplicationIdState, decisionsResponseState, responseCodeState, cardTypeState, firstNameState } from "../../store/atoms";
import { useProcessResponseCode } from "../../hooks";
import { useGetApplicationStatus } from "./hooks";
import { ApplicationFlowLayout } from "../../layouts";
import ProcessApplication from "../process-application";
import { CheckApplicationResponse, ProductTypePrns } from "../../types";
//import { queryKeys } from '../../utils/constants';
import {
    iconError,
    iconWarningInfo,
    applicationProcessing,
    iconCircleCheckMark,
    applicationClosed
} from "../../assets/img";
import { creditcard, creditcardPlusNoRibbon, creditcardLaunch } from "../../assets/img";
import classnames from "classnames";
import { debuglog } from '../../utils/debug';


type StatusMessageProps = {
    responseCode: string;
};

export const StatusMessage: React.FC<StatusMessageProps> = ({ responseCode }) => {
    let variant: string;
    let icon: string;
    let message: string;

    switch (responseCode) {
        case "WT_DOC":
            variant = "primary";
            icon = iconWarningInfo;
            message = "Attention needed: Information required";
            break;
        case "WT_FND":
            variant = "info";
            icon = iconCircleCheckMark;
            message = "Attention needed: Funding required";
            break;
        case "WT_FRE":
            variant = "secondary";
            icon = applicationProcessing;
            message = "Deposit Received";
            break;
        case "WT_FIS":
            variant = "info";
            icon = iconCircleCheckMark;
            message = "Approved";
            break;
        case "END_CLS":
            variant = "dark";
            icon = applicationClosed;
            message = "Application Closed";
            break;
        case "END_EXP":
            variant = "danger";
            icon = iconError;
            message = "Application Expired or Incomplete";
            break;
        case "END_DCL":
            variant = "danger";
            icon = iconError;
            message = "Application Declined";
            break;
        case "END_WDR":
            variant = "danger";
            icon = iconError;
            message = "Application Withdrawn";
            break;
        case "END_BRD":
            variant = "secondary";
            icon = iconCircleCheckMark;
            message = "Account Boarded";
            break;
        case "UI_MRV":
        case "UI_MDR":
            variant = "secondary";
            icon = iconCircleCheckMark;
            message = "Application In Review";
            break;
        case "UI_SDR":
            variant = "dark";
            icon = applicationProcessing;
            message = "Application Closed (Awaiting Refund)";
            break;
        case "InvalidEmail":
            variant = "danger";
            icon = iconError;
            message = "Attention needed: Invalid E-Mail Address";
            break;
        default:
            variant = "danger";
            icon = iconError;
            message = "Unknown Status";
            break;
    }

    return (
        <Alert variant={variant} style={{ padding: "0.5rem", marginTop: "1rem", width: "fit-content" }}>
            <img alt="" src={icon} role="presentation" style={{ maxHeight: "25px", color: "black" }} />
            <span style={{ paddingLeft: "14px", paddingRight: "0.5rem" }}>{message}</span>
        </Alert>
    );
};

type ApplicationStatusItemProps = {
    application: CheckApplicationResponse;
    isSelected?: boolean;
    onClick: (application: CheckApplicationResponse) => void;
};

const ApplicationStatusView: React.FC<ApplicationStatusItemProps> = ({ application, isSelected, onClick }) => {
    const parsedDate = new Date(application.submittedDateUtc || "");
    parsedDate.setSeconds(0, 0);
    const borderColor = isSelected ? "#817F7F" : "#D5D5D5";
    const borderThickness = isSelected ? "1.5px" : "1px";
    debuglog('App', application);
    debuglog('App Status - Incentive Id:', application.incentiveId, application.incentiveName);
    debuglog('App Status - Incentive:', application.incentive);    
    return (
        <li>
            <Card
                onClick={() => onClick(application)}
                style={{
                    border: `${borderThickness} solid ${borderColor}`,
                    cursor: "pointer"
                }}
                className={classnames("check-application", {
                    selected: isSelected
                })}
            >
                <Card.Body>
                    <Card.Title>
                        {application.prn && (
                            <>
                                <div className="application-card-name">
                                    {application.prn === ProductTypePrns.Plus && (
                                        <>
                                            <span>
                                                <img alt="credit card" src={creditcardPlusNoRibbon} />
                                            </span>
                                            <span>OpenSky Plus</span>                                            
                                        </>
                                    )}
                                    {application.prn === ProductTypePrns.Basic && (
                                        <>
                                            <span>
                                                <img alt="credit card" src={creditcard} />
                                            </span>
                                            <span>OpenSky</span>
                                        </>
                                    )}
                                    {application.prn === ProductTypePrns.Launch && (
                                        <>
                                            <span>
                                                <img alt="credit card" src={creditcardLaunch} />
                                            </span>
                                            <span>OpenSky Launch</span>
                                        </>
                                    )}
                                    {/*(!application.productType && (
                                        <>
                                        <span>{application.productName}</span>
                                        </>
                                    ))*/}
                                </div>
                            </>
                        )}
                        <div className="application-datetime">
                            <span>
                                {parsedDate.toLocaleDateString("default", {
                                    month: "short",
                                    day: "2-digit",
                                    year: "numeric"
                                })}
                            </span>
                            <span className="base-font-size text-muted"> | </span>
                            <span className="base-font-size text-muted">
                                {parsedDate.toLocaleTimeString(navigator.language, {
                                    hour: "2-digit",
                                    minute: "2-digit"
                                })}
                            </span>
                        </div>
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                        Application ID: {application.applicationID}
                    </Card.Subtitle>
                    <StatusMessage responseCode={application.responseCode} />
                </Card.Body>
            </Card>
        </li>
    );
};

const ViewApplication: React.FC = () => {
    const [responseCode, setResponseCode] = useRecoilState(responseCodeState);
    const [selectedApplicationId, setSelectedApplicationId] = useRecoilState(selectedApplicationIdState);
    const [, setDecisionsResponse] = useRecoilState(decisionsResponseState);
    const [applications, setApplications] = useState<CheckApplicationResponse[]>();
    const [selectedApplication, setSelectedApplication] = useState<CheckApplicationResponse>();
    const [, setCardtype] = useRecoilState(cardTypeState);
    const [, setFirstName] = useRecoilState(firstNameState);

    //const queryClient = useQueryClient();

    // fetch applications status(es)
    const { data: applicationsStatus, isLoading } = useGetApplicationStatus();

    // process response and redirect
    useProcessResponseCode(responseCode);

    const handleApplicationSelected = (application: CheckApplicationResponse) => {
        setSelectedApplication(application);
    };

    const handleCheckApplicationStatus = () => {
        setSelectedApplicationId(selectedApplication?.applicationID);
        setResponseCode(selectedApplication?.responseCode);

        setCardtype(getCardTypeByPrn(selectedApplication?.prn));
        setFirstName(selectedApplication?.firstName ?? '');
    };

    // useEffect(() => {
    //     // invalidate query and cleanup state in case screen needs to load again
    //     return () => {
    //         setApplicationId(undefined);
    //         setDecisionsResponse(undefined);
    //         setResponseCode(undefined);
    //         queryClient.invalidateQueries(...queryKeys.getApplicationsStatus);
    //     }
    // }, []);

    const getCardTypeByPrn = (prn: string | null | undefined) => {
        switch(prn){
            case ProductTypePrns.Plus:
                return 'plus';
            case ProductTypePrns.Launch:
                return 'launch';
            default:
                return 'basic';
        }
    }

    useEffect(() => {
        if (applicationsStatus) {
            setDecisionsResponse(applicationsStatus.done);
            debuglog('Decisions Response', applicationsStatus);

            const applicationResponses = applicationsStatus.done?.checkAppResponseList;
            setApplications(applicationResponses);

            if (applicationResponses?.length === 1) {
                setSelectedApplicationId(applicationResponses[0].applicationID);
                setResponseCode(applicationResponses[0].responseCode);
                debuglog('App Status(1) - Incentive Id:', applicationResponses[0].incentiveId, applicationResponses[0].incentiveName);
                debuglog('App Status(1) - Incentive:', applicationResponses[0].incentive);

                setCardtype(getCardTypeByPrn(applicationResponses[0].prn));
                setFirstName(applicationResponses[0].firstName);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationsStatus]);

    const isProcessing = isLoading || selectedApplicationId;

    if (isProcessing) {
        return <ProcessApplication />;
    }

    return (
        <ApplicationFlowLayout
            hideCardTypeNameInHeader={true}
            progress="0%"
        >
            <Row>
                <Col>
                    <h1 className="application-h1-padding">Check my application status</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        Select one of your applications from the list below to learn application status details and
                        actions you may need to take.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ul className="link-selector">
                        {applications?.map(application => (
                            <ApplicationStatusView
                                key={application.applicationID}
                                application={application}
                                onClick={handleApplicationSelected}
                                isSelected={selectedApplication?.applicationID === application.applicationID}
                            />
                        ))}
                    </ul>
                </Col>
            </Row>
            <Row className="row mt-36">
                <Col className="col text-center">
                    <Form.Group>
                        <Button disabled={!selectedApplication} onClick={handleCheckApplicationStatus}>
                            Select an Application
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
        </ApplicationFlowLayout>
    );
};

export default ViewApplication;
