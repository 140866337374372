/* eslint-disable no-useless-escape */
export const isNumeric = (value: string) => /^\d+$/.test(value);

export const emailPattern =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isValidEmail = (email: string) => emailPattern.test(email);

export const phonePattern = /^(?!(.)\1{9,})[2-9]\d{9}$/;
export const isValidPhone = (phone: string) => phonePattern.test(phone);

export const rawPhonePattern = /^(\d{3})(\d{3})(\d{4})$/;
export const isValidRawPhone = (phone: string | undefined) =>
    !!phone ? rawPhonePattern.test(phone) : false;


export const combinedSsnItinPattern = /\d{3}[- ]{0,1}\d{2}[- ]{0,1}\d{4}$/;
export const isValidSSNorITIN = (ssnOrItin: string) => {    

    if (!ssnOrItin.includes('-') && ssnOrItin.length < 9) {
        ssnOrItin = ssnOrItin.padStart(9, '0');
    }
    return combinedSsnItinPattern.test(ssnOrItin);    
}

export const isNotPOBoxPattern = /^(?!P\.?O\.?\sBox)/i;
export const isPOBoxRRPattern = /^(P\.?O\.?\sBox|R\.?R\.?)/i;

export const zipCodePattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const isValidZipCode = (zipCode?: string) => zipCodePattern.test(zipCode || '');

export const namePattern = /^[a-zA-Z\ \.\-]{1,20}$/;

export const isValidName = (name?: string) => namePattern.test(name || '');

export const invalidNameCharacters = /[^a-zA-Z\ \.\-]/gi;
export const invalidAddressCharacters = /[^a-zA-Z0-9 \.\-\\\/\&]/gi;

export const isValidDate = (
    year?: string,
    monthIndex?: string,
    day?: string
): boolean => {
    if (
        day !== undefined &&
        monthIndex !== undefined &&
        year !== undefined
    ) {
        // @ts-ignore
        const date = new Date(year, monthIndex, day);
        const dateMonth = date.getMonth();
        const dateYear = date.getFullYear();
        const dateDay = date.getDate();
        return (
            dateMonth === Number(monthIndex) &&
            dateYear === Number(year) &&
            dateDay === Number(day)
        );
    }
    return false;
};
