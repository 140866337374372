import React from 'react';
// import { LandingPageLayout } from '../../layouts';
// import { Intro } from '../../components';
import { useSetRecoilState } from 'recoil';
import { applicationState } from '../../store/atoms';
// import { routeHashes } from '../../app-routes';
// import { useHistory } from 'react-router-dom';
// import { configurationResponseState } from '../../store/atoms'
// import { currentCardInfo } from '../../utils/product-info';
// import * as FullStory from '@fullstory/browser';
import BasicInfo from '../basic-info';

const IntroBasic = () => {
    // const history = useHistory();
    const setApplication = useSetRecoilState(applicationState);

    // const configurationResponse = useRecoilValue(configurationResponseState);
    // const cardInfo = currentCardInfo('basic', configurationResponse?.securedProducts);
    const annualFee = '35';

    // const buttonClick = () => {
    //     setApplication((current) => ({
    //         ...current,
    //         ...{
    //             cardType: 'basic',
    //             fundToday: true,
    //             annualFee: annualFee,
    //         },
    //     }));
    //     //AppIntroGetStarted Full Story Event
    //     FullStory.event('AppIntroGetStarted', { });
    //     //history.push(routeHashes['/basic-info']);
    // }
    setApplication((current) => ({
        ...current,
        ...{
            cardType: 'basic',
            fundToday: true,
            annualFee: annualFee,
        },
    }));
    return (
        <BasicInfo/>
    );
};

export default IntroBasic;
